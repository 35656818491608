import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import slugify from 'slugify';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

import PageLayout from '../../layouts/Page';
import Metadata from '../../components/Metadata';
import Button from '../../components/Button';
import Faq from '../../components/Faq';

import Card from '../../components/Card';
import SwitchNavigation from '../../components/SwitchNavigation';
import CustomerQuote from '../../components/CustomerQuote';
import PancakeIntro from '../../components/PancakeIntro';
import CheckIcon from '../../assets/check.svg';

import { Link } from 'gatsby';

import './index.scss';

function PlanCard({ card }) {
  const mixpanel = useMixpanel();

  return (
    <Card theme="Light">
      <Card.Title>{card.title}</Card.Title>
      <Card.Content>
        <p>{card.description}</p>
      </Card.Content>
      <Card.CTA>
        <Button
          className="corporate-light"
          to={card._rawCallToAction?.url}
          onClick={() =>
            mixpanel.track(`Click ${card._rawCallToAction?.label}`)
          }
        >
          {card._rawCallToAction?.label}
        </Button>
        <div className="PricingCardFooter">
          <p
            className={
              card.price?.length < 5
                ? 'PricingCardAmount'
                : 'PricingCardContactSales'
            }
          >
            {card.price && card.price}{' '}
            {card.textBelowPrice && <span>{card.textBelowPrice}</span>}
          </p>
        </div>
      </Card.CTA>
    </Card>
  );
}

function PlanFeatureValue({ plan, value }) {
  return (
    <>
      <div className="FeaturesLinePlansItemName">{plan}</div>
      {value === 'Y' ? <CheckIcon className="CheckIcon" /> : value}
    </>
  );
}

function PricingPage({ location, serverData }) {
  const PricingPageQuery = useStaticQuery(graphql`
    query PricingQuery {
      sanityPricing {
        selfHosted_planCards {
          title
          textBelowPrice
          textAbovePrice
          price
          description
          _rawCallToAction
        }

        selfHosted_features {
          title
          features {
            description
            enterprise
            scale
            starter
            title
          }
        }

        selfHosted_FAQ {
          _rawPancakeIntro
          faqSection {
            question
            _rawAnswer
          }
        }

        saas_planCards {
          title
          textBelowPrice
          textAbovePrice
          price
          description
          _rawCallToAction
        }

        saas_features {
          title
          features {
            description
            enterprise
            scale
            starter
            title
          }
        }

        saas_FAQ {
          _rawPancakeIntro
          faqSection {
            question
            _rawAnswer
          }
        }

        customerQuotes {
          quote {
            quote
            author {
              name
              jobtitle
              image {
                ...ImageWithPreview
              }
              company {
                name
              }
            }
          }
        }
      }
    }
  `);

  const PricingPageData = PricingPageQuery.sanityPricing;

  const randomQuoteIndex = Math.floor(
    Math.random() * PricingPageData.customerQuotes.quote.length
  );

  const pricingCards = {
    'Self-Hosted': PricingPageData.selfHosted_planCards,
    SaaS: PricingPageData.saas_planCards,
  };

  const featuresGroups = {
    'Self-Hosted': PricingPageData.selfHosted_features,
    SaaS: PricingPageData.saas_features,
  };

  const FAQs = {
    'Self-Hosted': PricingPageData.selfHosted_FAQ || [],
    SaaS: PricingPageData.saas_FAQ || [],
  };

  const activePlan = serverData?.plan || 'Self-Hosted';

  const mixpanel = useMixpanel();

  return (
    <div className="PricingPage">
      <PageLayout>
        <Metadata
          title="Pricing"
          description="Okteto’s cloud-native development platform is for teams of all sizes. Self-hosted and cloud based options available. Free for teams of 3 or less!"
          bodyClass="PricingPageBody"
        />
        <section className="Plans" id="pricing">
          <div className="Pattern PatternBottom" />
          <div className="Pattern PatternTop" />

          <div className="PricingAnnouncementContainer">
            <Link className="PricingAnnouncement" to="/roi">
              <span className="PricingAnnouncementNew">NEW</span>
              Try the ROI calculator to see your potential savings →
            </Link>
          </div>

          <h1 className="PricingTitle text-center">Pricing</h1>

          <SwitchNavigation
            initialActiveSwitch={activePlan}
            switches={['Self-Hosted', 'SaaS']}
          />
        </section>
        <div className="ContainerLegacy">
          <div className="PricingCards">
            {pricingCards[activePlan].map((card) => (
              <PlanCard card={card} />
            ))}
          </div>
        </div>
        <CustomerQuote
          author={PricingPageData.customerQuotes.quote[randomQuoteIndex].author}
          className="PricingCustomerQuote"
        >
          {PricingPageData.customerQuotes.quote[randomQuoteIndex].quote}
        </CustomerQuote>
        <section className="Features">
          <div className="block">
            <div className="columns is-centered">
              <div className="column">
                <div className="FeaturesLine FeaturesLineHeader">
                  <div className="FeaturesLineDescription">
                    <h2 className="FeaturesLinePlan">{activePlan} Features</h2>
                  </div>
                  <div className="FeaturesLinePlans">
                    {pricingCards[activePlan].map((plan) => (
                      <div className="FeaturesLinePlansHeader">
                        <h3>{plan.title}</h3>
                        <Button
                          className="corporate-light transparent FeaturesCTA"
                          arrow
                          to={plan._rawCallToAction?.url}
                        >
                          {plan._rawCallToAction?.label}{' '}
                          {/* <ArrowIcon className="ArrowIcon PointRight" /> */}
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>

                {featuresGroups[activePlan].map((group) => (
                  <>
                    <div className="FeaturesLine FeaturesLineSection">
                      <h3>{group.title}</h3>
                    </div>
                    {group.features.map((feature, i) => {
                      const slug =
                        feature.title &&
                        slugify(feature.title, { lower: true });
                      return (
                        <div className="FeaturesLine" key={i} id={slug}>
                          <div className="FeaturesLineDescription">
                            {feature.title && (
                              <h4>
                                <a href={`#${slug}`}>{feature.title}</a>
                              </h4>
                            )}
                            {feature.description && (
                              <p>{feature.description}</p>
                            )}
                          </div>
                          <div className="FeaturesLinePlans">
                            {/* {activePlan !== 'SaaS' && (
                              <div className="FeaturesLinePlansItem">
                                {feature.starter !== null && (
                                  <PlanFeatureValue
                                    plan="Starter"
                                    value={feature.starter}
                                  />
                                )}
                              </div>
                            )} */}
                            <div className="FeaturesLinePlansItem">
                              {feature.scale && (
                                <PlanFeatureValue
                                  plan="Scale"
                                  value={feature.scale}
                                />
                              )}
                            </div>
                            <div className="FeaturesLinePlansItem">
                              {feature.enterprise && (
                                <PlanFeatureValue
                                  plan="Enterprise"
                                  value={feature.enterprise}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="CloudMarketplaces">
          <h2>Cloud Marketplaces</h2>
          <Card theme="Light">
            <svg
              width="33"
              height="27"
              viewBox="0 0 33 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.8047 7.26592H21.8047L24.6547 4.41592L24.7947 3.20592C23.1621 1.76488 21.189 0.763836 19.0619 0.297356C16.9348 -0.169124 14.7238 -0.0856401 12.638 0.539922C10.5521 1.16548 8.66013 2.31247 7.14079 3.87254C5.62145 5.4326 4.52489 7.35424 3.95471 9.45592C4.27221 9.32579 4.62393 9.30468 4.95471 9.39592L10.6547 8.45592C10.6547 8.45592 10.9447 7.97592 11.0947 8.00592C12.316 6.66459 14.0039 5.84082 15.8127 5.70325C17.6216 5.56568 19.4146 6.12472 20.8247 7.26592H20.8047Z"
                fill="#EA4335"
              />
              <path
                d="M28.7147 9.45593C28.0596 7.04356 26.7146 4.87487 24.8447 3.21593L20.8447 7.21593C21.6783 7.89709 22.3464 8.75867 22.7985 9.73567C23.2507 10.7127 23.475 11.7796 23.4547 12.8559V13.5659C23.9222 13.5659 24.3851 13.658 24.817 13.8369C25.249 14.0158 25.6414 14.2781 25.972 14.6086C26.3026 14.9392 26.5648 15.3317 26.7437 15.7636C26.9226 16.1955 27.0147 16.6584 27.0147 17.1259C27.0147 17.5934 26.9226 18.0564 26.7437 18.4883C26.5648 18.9202 26.3026 19.3127 25.972 19.6432C25.6414 19.9738 25.249 20.236 24.817 20.4149C24.3851 20.5938 23.9222 20.6859 23.4547 20.6859H16.3347L15.6247 21.4059V25.6759L16.3347 26.3859H23.4547C25.4429 26.4014 27.3833 25.7765 28.9889 24.6037C30.5945 23.4309 31.7799 21.7724 32.3698 19.8737C32.9597 17.9749 32.9228 15.9367 32.2646 14.0605C31.6063 12.1843 30.3616 10.5699 28.7147 9.45593Z"
                fill="#4285F4"
              />
              <path
                d="M9.20469 26.3459H16.3247V20.6459H9.20469C8.69742 20.6458 8.1961 20.5367 7.73468 20.3259L6.73469 20.6359L3.86469 23.4859L3.61469 24.4859C5.22412 25.7012 7.18796 26.3547 9.20469 26.3459Z"
                fill="#34A853"
              />
              <path
                d="M9.20469 7.85592C7.27551 7.86745 5.3981 8.48122 3.83468 9.61153C2.27126 10.7418 1.09991 12.3322 0.484221 14.1605C-0.131464 15.9889 -0.160733 17.9638 0.400502 19.8096C0.961737 21.6554 2.08545 23.2798 3.61469 24.4559L7.74469 20.3259C7.21985 20.0888 6.76038 19.7279 6.40569 19.2742C6.051 18.8204 5.81169 18.2874 5.70828 17.7209C5.60488 17.1543 5.64047 16.5711 5.81201 16.0213C5.98354 15.4716 6.2859 14.9716 6.69313 14.5644C7.10036 14.1571 7.60032 13.8548 8.1501 13.6832C8.69987 13.5117 9.28306 13.4761 9.84962 13.5795C10.4162 13.6829 10.9492 13.9222 11.4029 14.2769C11.8567 14.6316 12.2176 15.0911 12.4547 15.6159L16.5847 11.4859C15.7178 10.3527 14.6006 9.43533 13.3203 8.8056C12.04 8.17587 10.6314 7.85084 9.20469 7.85592Z"
                fill="#FBBC05"
              />
            </svg>

            <Card.Title>Google Cloud</Card.Title>
            <Card.Content>
              <p>
                Simplify procurement by purchasing Okteto through the Google
                Cloud Marketplace. Take advantage of flexible billing terms and
                your existing spend commitments/credits.
              </p>
            </Card.Content>
            <Card.CTA>
              <Button
                className="corporate-light"
                to="https://console.cloud.google.com/marketplace/product/okteto-public/okteto-bring-your-own-cloud"
                onClick={() =>
                  mixpanel.track(
                    `Click Get started on Google Cloud card from Cloud Marketplaces`
                  )
                }
              >
                Get started
              </Button>
            </Card.CTA>
          </Card>
        </section>
        {FAQs[activePlan].faqSection?.length > 0 && (
          <section className="Faq">
            <PancakeIntro
              title={FAQs[activePlan].pancakeIntro?.pancakeTitle}
              text={FAQs[activePlan].pancakeIntro?.pancakeText}
            />
            <div className="block">
              <div className="columns is-centered">
                <div className="column is-four-fifths text-center">
                  <h2>Frequently Asked Questions</h2>
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column is-four-fifths">
                  <Faq questions={FAQs[activePlan].faqSection} />
                </div>
              </div>
            </div>
          </section>
        )}
      </PageLayout>
    </div>
  );
}

export default PricingPage;

export async function getServerData(context) {
  try {
    const plans = ['SaaS', 'Self-Hosted'];

    return {
      props: {
        plan:
          context?.query?.plan && plans.includes(context?.query?.plan)
            ? context.query.plan
            : 'Self-Hosted',
      },
    };
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}
