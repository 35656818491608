import React, { useState } from 'react';
import { Link } from 'gatsby';

import "./SwitchNavigation.scss"

const SwitchNavigation = props => {

  const { initialActiveSwitch, switches, onClick } = props;

  const [activeSwitch, setActiveSwitch] = useState(initialActiveSwitch)

  const changeActiveSwitch = (event, button) => {
    onClick(event);
    setActiveSwitch(button);
  }

  return (
    <nav className="SwitchNavigation">
      {switches.length > 0 &&
        <ul>
          {switches.map((button, index) => (
            <li key={index}>
              <Link className={activeSwitch === button ? "SwitchNavigationActive" : ""} href={`/pricing/?plan=${button}`}>{button}</Link>
            </li>
          ))}
        </ul>
      }
    </nav>
  )
}

export default SwitchNavigation;